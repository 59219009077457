<template>
  <nuxt-link
    id="logo-hh"
    :to="'/'"
    class="block"
    :class="isDesktop ? 'absolute top-[-18px]' : null"
  >
    <HhImage
      :src="ImageLogoFull"
      :sources="sourceImage"
      :use-mutator="false"
      alt="hungryhub logo"
      class="w-[30px] lg:h-[38px] lg:w-[130px]"
      :width="90"
      :height="50"
    />
  </nuxt-link>
</template>

<script lang="ts" setup>
import { storeToRefs } from "pinia";
import { type SrcSet } from "~/components/HhImage.vue";
import HhImage from "~/components/HhImage.vue";
import ImageLogoFull from "~/assets/image/logo-new-full.png";
import ImageLogoAlternate from "~/assets/image/logo-new-full-alternate.png";
import useClientTypeStore from "~/stores/clientType";
const clientTypeStore = useClientTypeStore();
const { isDesktop } = storeToRefs(clientTypeStore);

const sourceImage: SrcSet[] = [
  {
    source: `${ImageLogoAlternate}`,
    useMutator: false,
    breakpoint: { maxWidth: 768 },
    width: 30,
  },
  { source: `${ImageLogoFull}`, useMutator: false, pixelDensity: "2x" },
];
</script>

<script lang="ts">
export default {
  name: "Logo",
};
</script>
